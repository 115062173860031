@import "./colors";

h1 {
    font-size: 60px;
    line-height: 70px;
}
h2 {
    font-size: 30px;
    line-height: 35px;
}
h3 {
    font-size: 25px;
    line-height: 29px;
}
h4 {
    font-size: 20px;
    line-height: 23px;
}
h5 {
    font-size: 18px;
}
h1,
h2,
h3,
h4 {
    color: $black;
    font-weight: initial;
}
a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
}

.paper-background-color {
  background-color: $white;
}

.app-background-color {
  background-color: $white;
}

.opaque-font {
  color: $opaque-font-color;
}

.dark-font {
  color: $dark-color;
}

.darker-font {
  color: $black;
}

.white-font {
  color: $white;
}

.primary-colored-font {
  color: $primary-color!important;
}

.warning-font {
  color: $warning-color;
}
.white-font {
  color: $white;
}

.primary-colored-background {
  background: $primary-color;
}
.warning-colored-background {
  background: $warning-color;
}

.svg-primary-color {
  path {
    fill: $primary-color !important;
  }
}

.svg-white {
  path {
    fill: $white !important;
  }
}

.svg-black {
  path {
    fill: $black !important;
  }
}

.svg-dark-grey {
  path {
    fill: $dark-color !important;
  }
}

.svg-opaque-grey {
  path {
    fill: $opaque-font-color !important;
  }
}

.cursor-pointer {
  cursor: pointer;

  &:hover {
    opacity: 0.85;
    transition: 0.3s;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}