@import "./main.scss";

body {
    overflow-x: hidden; // no scroll in x-axis
    margin: 0px;
    background-color: $body;

    ::-webkit-scrollbar {
        display: none;
    }

    font-family: 'Silka',
    sans-serif;

    .sf-app__content {
        width: 100%;
        position: relative;
    }

    #floating_button_sirena {
        z-index: 100 !important;
    }

    .RappiPagaCheckout-MuiDialog-root {
        z-index: 1305 !important;
    }
}

@for $i from 1 through 10 {
    .font-weight-#{$i * 100} {
        font-weight: $i * 100 !important;
    }

    // padding utils
    .ph-#{$i} {
        padding-left: #{$i * 8}px !important;
        padding-right: #{$i * 8}px !important;
    }

    .pv-#{$i} {
        padding-top: #{$i * 8}px !important;
        padding-bottom: #{$i * 8}px !important;
    }

    .pt-#{$i} {
        padding-top: #{$i * 8}px !important;
    }

    .pr-#{$i} {
        padding-right: #{$i * 8}px !important;
    }

    .pb-#{$i} {
        padding-bottom: #{$i * 8}px !important;
    }

    .pl-#{$i} {
        padding-left: #{$i * 8}px !important;
    }

    // margin utils
    .mh-#{$i} {
        margin-left: #{$i * 8}px !important;
        margin-right: #{$i * 8}px !important;
    }

    .mv-#{$i} {
        margin-top: #{$i * 8}px !important;
        margin-bottom: #{$i * 8}px !important;
    }

    .mt-#{$i} {
        margin-top: #{$i * 8}px !important;
    }

    .mr-#{$i} {
        margin-right: #{$i * 8}px !important;
    }

    .mb-#{$i} {
        margin-bottom: #{$i * 8}px !important;
    }

    .ml-#{$i} {
        margin-left: #{$i * 8}px !important;
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.sf-chart-tooltip {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: initial;
    position: absolute;
    border: none;
    color: $black;
    font-size: 12px;
    border-radius: 4px;
    z-index: 99999 !important;
    pointer-events: none;
    box-shadow: rgba(74, 74, 74, 0.290196) 0px 2px 4px;
    transition: all 0.2s linear;
    padding: 15px;
    max-width: 400px;
    transform: translateY(-20%);
    background-color: $white;

    &::before {
        content: " ";
        position: absolute;
        pointer-events: none;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        top: calc(16% - 6px);
        border-right: 6px solid $white;
    }

    &.tooltip-arrow-left {
        &::before {
            left: -5px;
        }
    }

    &.tooltip-arrow-right {
        &::before {
            transform: rotate(180deg);
            right: -5px;
        }
    }


    &--white {
        background-color: $white;

        &::before {
            border-right: 6px solid $white;
        }
    }

}

.tooltip,
.tooltip-white {
    min-width: 66px;
    text-align: left;
    opacity: 0.9;
    z-index: 10000;
    border-radius: 4px 0px 4px 4px;
    position: absolute;
    position: relative;

    h3,
    h4,
    p {
        font-size: 14px;
    }

    h3,
    h4 {
        font-weight: 600;
        line-height: 14px;

    }

    h4,
    p {
        color: $dark-color;
        margin: 0;
    }

    h4 {
        margin: 5px 0;
    }

    h3 {
        color: $primary-color;
        margin: 0 0 10px 0;
    }

    p {
        font-weight: 400;
        line-height: 16px;
    }

    .primary-font-color {
        color: $primary-color;
    }

    .alert-font-color {
        color: $error-color;
    }
}

.tooltip-white {
    background-color: $white;

    h4,
    p {
        color: $black;
    }
}

.card {
    background: $white;
    border-radius: 16px;

}

.card_height {
    height: 100%;
    padding: 2rem;
}

.sf-title {
    font-size: 30px;
    font-weight: 600;
    color: $dark-color;
}

.sf-title-secondary {
    font-weight: 600;
    font-size: 25px;
    color: $dark-color;
}

.symbol {
    color: $primary-color;
    padding-right: 5px;
}

.primary-font-color {
    color: $primary-color;
}