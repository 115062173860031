@import 'src/main.scss';

.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
}

.caption-info {
    margin: 10px 0px;
    font-size: 10px;
    color: #00000099;
    width: 60%;
}

.react-datepicker-wrapper {
    width: 100%;
}