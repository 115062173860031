$body: #e5e5e56b;
$grey: #b2b2b2;
$light-grey: rgba(45, 45, 45, 0.05);
$white: #fff;
$yellow: #F9D82D;
$red: #f84363;
$accent-color: #fec524;
$opacite-yellow: #fac682;
$yellow-light-solid: #fcff3c1c;
$orange-light: #f9b863;
$dark-orange: #dc8719;
$warning-color: #ff0000;
$info-color: #EC923F;
$strong-grey: #3e3e3e;
$grey-with-opacity: #aabdce80;
$grey-scale2: #818181;
$grey-scale3: #ABABAB;
$grey-solid: #808080;
$opaque-font-color: #8c8c8c;
$light-grey: #F5F5F5;
$lighter-gray: #F4F4F4;
$dark-color: #2D2D2D;
$black: #000000;
$footer-color: #F9F9F9;
$error-color: #F65858;
$purple-color: #BD5DFB;
$secondary-purple-color: #830EDF;
$purple-dark: #7F49BD;
$primary-color: #024f21;
$look-below: #92E0B4;
$dark-green: #369D65;
$dark-green-solid: #00773714;
$water-green: #c8dcd0;
$dark-blue: #3B5998;
$discount-red: #F65858;
$discount-blue: #1871C4;